import React from 'react'
import Cookies from 'js-cookie'
import RootWrapper from '@/components/RootWrapper'
import { CookieConsent } from '@gworks/react-gw-components'
import { Categories, Scripts, DefaultSettings, ModalSettings, BannerContent } from './cookieconfig'
import { Helmet } from 'react-helmet'
const lang = 'fi'

export const wrapRootElement = ({ element }) => {

  // THIS IS A HOTFIX, REMOVE ONCE COOKIECONSENT SUPPORTS EXTERNAL ASYNC FILES
  const cookiesEnabled = Cookies.get('rengasvalmistajat_consent')
  if (!cookiesEnabled) Cookies.remove(`_ga_${process.env.GATSBY_GA_MEASUREMENT_ID?.replace('G-', '')}`, { path: '' }) // removed!
  // THIS IS A HOTFIX, REMOVE ONCE COOKIECONSENT SUPPORTS EXTERNAL ASYNC FILES

  return (
    <RootWrapper>
      {element}
      <CookieConsent
        cookie='rengasvalmistajat_consent'
        modalClasses='font-helvetica'
        acceptButtonText='Hyväksy'
        declineButtonText='Kiellä'
        settingsLabel='asetuksista'
        bannerContent={BannerContent.banner[lang]}
        modalSettings={ModalSettings}
        defaultUserChoices={DefaultSettings}
        currentLang={lang}
        expirationTime={7}
        categories={Categories}
        scripts={Scripts}
        contentClasses='text-white text-2xl'
        buttonClasses='rounded ease-in duration-100 box-border px-4 py-2 bg-secondary hover:bg-black border-secondary border-2 text-black hover:text-secondary text-2xl'
      />

      {/* // THIS IS A HOTFIX, REMOVE ONCE COOKIECONSENT SUPPORTS EXTERNAL ASYNC FILES */}
      {cookiesEnabled && (
        <Helmet>
          <script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}></script>
        </Helmet>
      )}
      {/* // THIS IS A HOTFIX, REMOVE ONCE COOKIECONSENT SUPPORTS EXTERNAL ASYNC FILES */}
    </RootWrapper>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const onRouteUpdate = ({ location }) => {
  let gtag = window[window['google_tag_manager'] || 'gtag']
  if (typeof gtag == 'function') {
    gtag('event', 'page_view', {
      page_title: location.href,
      page_location: location.href,
      page_path: location.pathname,
      send_to: `${process.env.GATSBY_GA_MEASUREMENT_ID}`,
    })
  }
}

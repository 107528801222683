import fetch from 'cross-fetch'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GATSBY_WPGRAPHQL_URL || 'http://kubernetes.docker.internal:8001/graphql',
    fetch,
  }),
  cache: new InMemoryCache(),
})

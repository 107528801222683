import { CookieCategory, CookieSettings, ModalSettings as MS, Script } from './cookiedefinitions'

export const Categories: Array<CookieCategory> = [
  {
    id: 1,
    name: {
      en: 'Strictly necessary cookies',
      fi: 'Välttämättömät evästeet',
      sv: 'Nödvändiga cookies',
    },
    enabled: true,
    description: {
      en: 'Necessary cookies are cookies that are required in order for this page to function correctly. Regarding this page, necessary cookies are used only to remember what cookie settings you have chosen.',
      fi: `Välttämättömät evästeet ovat evästeitä, joita sivun tavanomainen käyttö edellyttää. Tällä sivustolla välttämättömiä evästeitä käytetään ainoastaan siihen, että tekemäsi valinnat hyväksytyistä tai hylkäämistäsi evästeistä voidaan muistaa.`,
      sv: 'Nödvändiga cookies är sådana som krävs för normal användning av denna hemsida. De cookies som krävs på denna hemsida används endast för att komma ihåg de val du gör om de cookies du accepterar eller avvisar.',
    },
    cookies: [],
    isChangeable: false,
  },
  {
    id: 2,
    name: {
      en: 'Marketing cookies',
      fi: 'Markkinoinnilliset evästeet',
      sv: 'Marketing cookies',
    },
    enabled: false,
    description: {
      en: 'This website uses Google Analytics -analytics tools to track user behaviour. This information is used to generate anonymous information on how the website is used so we may further improve the website. Your personal information is disclosed to a third party: Google uses this information to target their marketing based on your behavior according to their own privacy policy.',
      fi: `Sivusto käyttää Google Analytics -palvelua, jota käytämme kävijämäärien ja sivustojen käyttötapojen analysoinnissa. Google Analytics yhdistää näitä tietoja omiin käyttäjätietoihinsa oman <a href="https://policies.google.com/privacy?hl=fi">tietosuojapolitiikkansa</a> mukaisesti. Hyväksymällä kolmannen osapuolen evästeet mahdollistat näiden tietojen käytön.`,
      sv: 'Webbplatsen använder Google Analytics -analytiklösningar för att spåra webbplatsanvändning. Utifrån denna information genererar vi anonymiserad information om hur sidan används, och vi förbättrar sidan ytterligare baserat på denna information. Din information kommer att vidarebefordras till tredje part: Google använder informationen som samlas in i detta sammanhang för att rikta sin egen marknadsföring i enlighet med sin egen sekretesspolicy.',
    },
    cookies: ['_gid', '_gat', '_ga'],
    isChangeable: true,
  },
]

export const Scripts: Array<Script> = [
  {
    name: 'necessary',
    script: '',
    cookieCategory: 1,
    cookies: [],
  },
  {
    name: 'Analytics',
    script: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}');
    `,
    cookieCategory: 2,
    cookies: ['_gid', '_gat', '_ga', `_ga_${process.env.GATSBY_GA_MEASUREMENT_ID?.replace('G-', '')}`],
  },
]

export const DefaultSettings: Array<CookieSettings> = [
  {
    id: 1,
    enabled: true,
  },
  {
    id: 2,
    enabled: false,
  },
]

export const ModalSettings: MS = {
  visible: false,
  title: {
    en: 'Cookie overview',
    fi: 'Evästeistä yleisesti',
    sv: 'Cookies allmänt',
  },
  description: {
    en: '...',
    fi: `
    Käytämme sivustolla evästeitä, joista muodostuu EU:n Tietosuoja-asetuksen tarkoittama henkilörekisteri, jonka rekisterinpitäjä on Teknisen Kaupan Liitto ry. Evästeet ovat pieniä, selaimeen tallennettavia tiedostoja, joita ilman käyttäjää ei pystytä sivustolla yksilöimään.
    Tästä näkymästä hyväksytään tai hylätään eri tyyppisiä evästeitä erikseen. Suostumuksen voi milloin tahansa peruuttaa ja hyväksyntä on voimassa enintään 7 vrk ajan.
    `,
    sv: '...',
  },
  buttons: {
    ui: {
      isRounded: true,
    },
    yes: {
      en: 'Accept',
      fi: 'Hyväksy',
      sv: 'Godkänn',
    },
    no: {
      en: 'Decline',
      fi: 'Hylkää',
      sv: 'Neka',
    },
    footer: {
      enable_all: {
        en: 'Enable all',
        fi: 'Hyväksy kaikki',
        sv: 'Godkänn alla',
      },
      disable_all: {
        en: 'Disable all',
        fi: 'Hylkää kaikki',
        sv: 'Neka alla',
      },
      refresh: {
        en: 'Refresh',
        fi: 'Päivitä',
        sv: 'Uppdatera',
      },
    },
  },
}

export const BannerContent = {
  banner: {
    en: 'This website uses cookies to gather information on its visitors. You can find out more about which cookies we are using or switch them off from the',
    fi: 'Sivustolla käytetään evästeitä. Jotkin sivuston toiminnot edellyttävät evästeiden käyttöä toimiakseen, minkä lisäksi keräämme käyttäjätietoja sivuston kehittämistä varten. Voit määritellä suostumuksesi evästeitä koskien',
    sv: 'Vi använder cookies på webbplatsen för att samla in personligt identifierbar information om dig. Du kan bekanta dig med de cookies vi använder och acceptera dem enskilt via',
  },
  buttons: {
    accept: {
      en: 'Accept',
      fi: 'Hyväksy',
      sv: 'Godkänn',
    },
    decline: {
      en: 'Decline',
      fi: 'Hylkää',
      sv: 'Neka',
    },
    settings: {
      en: 'settings',
      fi: 'asetuksista',
      sv: 'inställningarna',
    },
  },
}

export const Notifications = {
  all_enabled: {
    en: 'All cookies enabled',
    fi: 'Evästeet hyväksytty',
    sv: 'Alla cookies godkända',
  },
  all_disabled: {
    en: 'All cookies disabled',
    fi: 'Evästeet hylätty',
    sv: 'Alla cookies nekade',
  },
  refresh: {
    en: 'Click to reload',
    fi: 'Lataa sivu uudelleen',
    sv: 'Ladda sidan om',
  },
  updated: {
    en: 'Settings saved',
    fi: 'Asetukset tallennettu',
    sv: 'Inställningarna sparade',
  },
  not_changeable: {
    en: 'Category not changeable',
    fi: 'Kategoriaa ei voi muuttaa',
    sv: 'Kategorin kan inte ändras',
  },
}

import React from 'react'
import 'focus-visible'
import '@fontsource/manrope/variable.css'
import '@fontsource/dongle/latin.css'
import '@gworks/react-gw-components/dist/esm/dist/styles/global.css'
import '@/styles/global.css'
import '@/styles/gutenberg.css'
import '@/styles/gdpr.css'
import { ApolloProvider } from '@apollo/client'
import { client } from '../../../apollo/apolloClient'

export interface RootWrapperProps {
  /** Site content */
  children: React.ReactNode
}

/** This component wraps the whole application in App/Test/Storybook environments. Pass all global providers here and add global imports at the top of the file */
const RootWrapper = ({ children }: RootWrapperProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default RootWrapper

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-posts-archive-tsx": () => import("./../../../src/templates/Posts/Archive.tsx" /* webpackChunkName: "component---src-templates-posts-archive-tsx" */),
  "component---src-templates-posts-single-tsx": () => import("./../../../src/templates/Posts/Single.tsx" /* webpackChunkName: "component---src-templates-posts-single-tsx" */),
  "component---src-templates-wheel-infos-archive-tsx": () => import("./../../../src/templates/WheelInfos/Archive.tsx" /* webpackChunkName: "component---src-templates-wheel-infos-archive-tsx" */),
  "component---src-templates-wheel-infos-single-tsx": () => import("./../../../src/templates/WheelInfos/Single.tsx" /* webpackChunkName: "component---src-templates-wheel-infos-single-tsx" */),
  "component---src-templates-wheel-types-archive-tsx": () => import("./../../../src/templates/WheelTypes/Archive.tsx" /* webpackChunkName: "component---src-templates-wheel-types-archive-tsx" */),
  "component---src-templates-wheel-types-single-tsx": () => import("./../../../src/templates/WheelTypes/Single.tsx" /* webpackChunkName: "component---src-templates-wheel-types-single-tsx" */)
}

